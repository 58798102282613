<template>
    <div>
      <apexchart
        type="bar"
        :options="chartOptions"
        :series="[{ data: seriesData }]"
      ></apexchart>
    </div>
  </template>
  
  <script>
  import VueApexCharts from 'vue-apexcharts';
  
  export default {
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      seriesData: {
        type: Array,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        chartOptions: {
          chart: {
            type: "bar",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
                barHeight: '80%',
                isFunnel: true,
            },
          },
          colors: ["#1E88E5", "#F4511E"], // Custom colors for revenue and profit
          xaxis: {
            categories: this.$props.categories, // Labels for the bars
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "14px",
              colors: ["#000"],
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
              },
          },
          tooltip: {
            enabled: true,
          },
          title: {
            text: "Retorno Total/Lucro",
            align: "center",
          },
          legend: {
            show: true,
          },
        },
      };
    },
  };
  </script>
  
  <style scoped>
  /* Optional styling */
  </style>
  