<template>
    <div>
      <!-- Pie Chart -->
      <apexchart 
        type="pie" 
        :options="chartOptions" 
        :series="values" 
      />
    </div>
  </template>
  
  <script>
  import VueApexCharts from "vue-apexcharts";
  
  export default {
    name: "PieChartComponent",
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      legends: {
        type: Array,
        required: true,
      },
      values: {
        type: Array,
        required: true,
      },
    },
    computed: {
      chartOptions() {
        return {
          chart: {
            type: "pie",
          },
          labels: this.legends, // Set labels using props
          legend: {
            position: "bottom",
          },
          responsive: [
            {
              breakpoint: 768,
              options: {
                chart: {
                  width: "100%",
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        };
      },
    },
  };
  </script>
  
  <style scoped>
  /* Optional styles for responsiveness */
  </style>
  