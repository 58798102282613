<template>
    <div>
      <h1 :style="{ margin: '20px' }">Relatório de Vendas - {{ (this.chosen_country === 'all') ? 'Geral' : this.chosen_country}}</h1>
      
      <div class="date-picker">
      <div class="date-picker-container">
        <!-- Start Date -->
        <label for="start-date">De:</label>
        <input
          type="date"
          id="start-date"
          v-model="startDate"
          :max="endDate"
        />
        
        <!-- End Date -->
        <label for="end-date">até:</label>
        <input
          type="date"
          id="end-date"
          :min="startDate"
          v-model="endDate"
        />
      </div>
      <div class="search">
        <button class="search-btn" @click="searchBtn" :disabled="loading">
          <span v-if="!loading">Procurar</span>
          <span v-else class="pulse-loader"></span>
        </button>
      </div>
    </div>
    <button @click="toggleDashboard" class="dropdown-button">
      {{ isDashboardVisible ? "Hide Dashboard" : "Show Dashboard" }}
    </button>
    <button @click="downloadCSV" class="dropdown-button">
      Download CSV
    </button>
    <div class="country-filter">
      <label for="filter">Filtro País</label>
      <select class="state_filter" v-model="chosen_country" id="chosen_country" required @change="changeOnDisplayOrders">
        
        <option v-for="state in this.country_list" :key="state" :value="state">
          {{ state }}
        </option>
      </select>
    </div>
    <div class="dashboard">
      <div class="card-container" v-if="isDashboardVisible">
        <div class="card" v-for="(card, index) in cards" :key="index">
          <div class="card-value">
            <span v-if="loading"><pulse-loader :loading="loading" :color="'#FF9393'"></pulse-loader></span>
            <span v-else>{{ card.value }}</span>
          </div>
          <div class="card-label">{{ card.label }}</div>
        </div>
      </div>
    </div>

    <div class="dashboard">
      <div class="card-container-graphs" v-if="isDashboardVisible">
        <div class="card">
          <h5 class="card-title">Gráfico Profit - {{ this.chosen_country }}</h5>
          <FunnelChart :seriesData="profit_chart_values" :categories="['Total Revenue', 'Total Profit']" />
        </div>
        <div class="card" :style="{ gridColumn: 'span 2' }">
          <h5 class="card-title">Distribuição Por País - {{ this.chosen_country }}</h5>
          <BarGraph :profit_data="this.profits" :revenue_data="this.revenues" :categories="this.countries"/>
        </div>
        <div class="card">
          <h5 class="card-title">Free Shipping Info - {{ this.chosen_country }}</h5>
          <PieChart :legends="['Free Shipping', 'Paid Shipping']" :values="[parseFloat(this.free_shipping_percentage), parseFloat(this.paid_shipping_percentage)]"/>
        </div>
      </div>  
    </div>
      <table>
        <thead>
          <tr>
            <th>ID Encomenda</th>
            <th>Nr Tracking</th>
            <th>País</th>
            <th>Preço Total Cliente (€)</th>
            <th>Preço Total IDIVIA (€)</th>
            <th>Margem de Lucro (€)</th>
            <th>Margem de Lucro (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders_on_display" :key="order.id">  
            <td>{{ order.order_id }}</td>
            <td>{{ order.tracking_id }}</td>
            <td>{{ order.country_full }}</td>
            <td>{{ order.total_price_client }}</td>
            <td>{{ order.total_price_idivia }}</td>
            <td>{{ order.profit_margin }}</td>
            <td>{{ order.profit_margin_percentage }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>


<script>
import { ref } from "vue";
import { mapGetters } from "vuex/dist/vuex.common.js";
import FunnelChart from "../components/FunnelChart.vue";
import BarGraph from "../components/BarGraph.vue";
import PieChart from "../components/PieChart.vue";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  data: function() {
    return {
      isDashboardVisible: false,
      orders: [],
      orders_on_display: [],
      startDate: ref(""),
      endDate: ref(""),
      total_orders: 0,
      total_profit: 0,
      total_revenue: 0,
      avg_profit_margin: 0,
      free_shipping_percentage: 0,
      paid_shipping_percentage: 0,
      cards: [
        { value: this.total_orders, label: "Total Orders" },
        { value: this.total_profit, label: "Total Profit" },
        { value: this.total_revenue, label: "Total Revenue" },
        { value: this.avg_profit_margin, label: "AVG Profit Margin" },
        { value: this.free_shipping_percentage, label: "Free Shipping Percentage" },
        { value: this.paid_shipping_percentage, label: "Paid Shipping Percentage" },
      ],
      profit_chart_values: [], // 0-> total revenue; 1-> total profit
      country_parameters: [],
      countries: [],
      country_list: [],  // diferente de countries pq tem a opção "all", necessária para o filtro de países mas que nao pode estar no array que vai para o grafico
      profits: [],
      revenues: [],
      chosen_country: "all",
      loading: false,
    };
  },
  components: {
    FunnelChart,
    BarGraph,
    PieChart,
    PulseLoader
  },
  methods: {
    async getOrders() {
      this.loading = true;
      this.chosen_country = "all";
      this.cards.forEach(card =>{
        card.value = "A carregar..."
      });
      this.free_shipping_percentage = 0;
      this.paid_shipping_percentage = 0;
      this.orders = [];
      this.orders_on_display = [];
      this.profit_chart_values = [];
      this.country_parameters = [];
      this.countries = [];
      this.country_list = [];
      this.profits = [];
      this.revenues = [];
      try {
        const payload = {
          startDate: this.startDate,
          endDate: this.endDate,
        };
        await this.$store.dispatch('getOrdersFull', payload);
        this.orders = this.getOrdersFull.orders_final;
        this.total_orders = this.getOrdersFull.total_orders;
        this.total_profit = this.getOrdersFull.total_profit;
        this.total_revenue = this.getOrdersFull.total_revenue;
        this.profit_chart_values = [this.getOrdersFull.total_revenue, this.getOrdersFull.total_profit];
        this.changeOnDisplayOrders();
        this.country_list = [];
        this.country_list.push("all");
        this.country_list = this.country_list.concat(this.countries); // igualar country_list a countries e adicionar opçao "all"
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "oops",
          text: error
        });
      } finally{
        this.loading = false;
      }
    },
    changeOnDisplayOrders(){
      if(this.chosen_country === "all"){
        this.orders_on_display = this.orders;
      }
      else{
        this.orders_on_display = [];
        for(let index = 0; index < this.orders.length; index++){
          if(this.orders[index].country_full === this.chosen_country){
            this.orders_on_display.push(this.orders[index]);
          }
        }
      }

      this.assignCountryParameters(this.orders_on_display);
      this.cards[0].value = this.orders_on_display.length;
      this.cards[1].value = this.profit_chart_values[1] + "€";
      this.cards[2].value = this.profit_chart_values[0] + "€";
      this.cards[3].value = ((this.profit_chart_values[1] / this.profit_chart_values[0] )* 100).toFixed(2) + "%"; // avg profit margin
      // calcular percentagens de freeshipping (calculo estes valores totais no backend e para cada país no frontend para calcular só quando necessário, assim como o avg profit margin)
      let free_shipping_qty = 0;
      for (let i = 0; i < this.orders_on_display.length; i++){
        if (this.orders_on_display[i].free_shipping){
          free_shipping_qty++;
        }
      }
      this.free_shipping_percentage = ((free_shipping_qty / this.total_orders) * 100).toFixed(2);
      this.paid_shipping_percentage = (100 - parseFloat(this.free_shipping_percentage)).toFixed(2);
      this.cards[4].value = this.free_shipping_percentage + "%";
      this.cards[5].value = this.paid_shipping_percentage + "%";
    },
    async searchBtn(){
      await this.getOrders();
    }, 
    downloadCSV(){
      // define csv headers
      const headers = Object.keys(this.orders_on_display[0]);

      const csvRows = [];

      csvRows.push(headers.join(','));

      this.orders_on_display.forEach(row => {
        csvRows.push(headers.map(header => row[header]).join(','));
      });

      const csvContent = csvRows.join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

      // Create a download link and trigger download
      const link = document.createElement('a');
      if (link.download !== undefined) { // for browsers that support download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        const filename = 'countrydata-' + this.chosen_country + '.csv';
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    },
    toggleDashboard() {
      this.isDashboardVisible = !this.isDashboardVisible;
    },
    assignCountryParameters(orders){
    this.country_parameters = [];
      orders.forEach(order => {
        if(this.country_parameters.some((item) => item.country === order.country_full)){ // Se existe, total revenue += order.total_price_client e total profit += order.profit_margin
          this.country_parameters.find((item) => item.country === order.country_full).total_revenue += parseFloat(order.total_price_client);
          this.country_parameters.find((item) => item.country === order.country_full).total_profit += parseFloat(order.profit_margin);

        }
        else{ // Se não existe, criar um novo objeto com country, total revenue e total profit
          this.country_parameters.push({
            country: order.country_full,
            total_revenue: parseFloat(order.total_price_client),
            total_profit: parseFloat(order.profit_margin),
          });
        }
      });
      this.country_parameters.sort((a, b) => a.country.localeCompare(b.country));
      this.countries = this.country_parameters.map((item) => item.country);
      this.profits = this.country_parameters.map((item) => parseFloat(item.total_profit.toFixed(2)));
      this.revenues = this.country_parameters.map((item) => parseFloat(item.total_revenue.toFixed(2)));
      this.profit_chart_values = [this.revenues.reduce((accumulator, currentValue) => parseFloat((accumulator + currentValue).toFixed(2)), 0), this.profits.reduce((accumulator, currentValue) => parseFloat((accumulator + currentValue).toFixed(2)), 0)];
    },
    subtractMonth(dateString){
      // Parse the input date string (yyyy-mm-dd)
      const date = new Date(dateString);

      // Subtract one month
      date.setMonth(date.getMonth() - 1);

      // Format back to yyyy-mm-dd
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
  },
  computed: {
    ...mapGetters(['getOrdersFull']),
    
    },
  async created(){
    this.endDate = new Date().toISOString().split('T')[0];
    this.startDate = this.subtractMonth(this.endDate);
    await this.getOrders();
  }
  
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

tbody td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

h1 {
  margin-bottom: 20px;
  text-align: center;
}

.date-picker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-picker h3, label, input, span, t{
  margin: 0 5px;
}

.dashboard {
  padding: 10px;
}


.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card-container-graphs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.card {
  display: flex;
  justify-content: center;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-value {
  font-size: 32px;
  font-weight: bold;
  color: #FF9393;
}

.card-label {
  font-size: 16px;
  color: #555;
  margin-top: 5px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-btn {
  background-color: rgb(255, 147, 147);
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-left: 20px;
}

.search-btn:hover {
  background-color: rgb(255, 147, 147, 0.658);
}

.dropdown-button {
  background-color: rgb(255, 147, 147);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-left: 20px;
}

.dropdown-button:hover {
  background-color: rgb(255, 147, 147, 0.658);
}

.country-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

/* Pulse loader style */
.pulse-loader {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  background-color: white;
  border-radius: 50%;
  animation: pulse 0.8s infinite ease-in-out;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}
</style>