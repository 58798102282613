<template>
    <div>
        <apexchart class="child-component" type="bar" :key="this.chartKey" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'

    export default {
        props: {
            profit_data: {
                type: Array,
                required: true,
            },
            revenue_data:{
                type: Array,
                required: true,
            },
            categories: {
                type: Array,
                required: true,
            },
        },
        data: function() {
            return {
            options: {
                chart: {
                id: 'vuechart-example'
                },
                xaxis: {
                categories: this.$props.categories
                }
            },
            series: [{
                name: 'Total Revenue',
                data: this.$props.revenue_data
            }, {
                name: 'Total Profit',
                data: this.$props.profit_data
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        borderRadius: 5,
                        borderRadiusApplication: 'end'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: this.$props.categories,
                },
                yaxis: {
                    min: 0,
                    labels: {
                        formatter: function (val) {
                            return Math.round(val); // Round all values to integers
                        },
                    },
                    title: {
                        text: 'EUR €'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                        return "€ " + val
                        }
                    }
                }
            },
            chartKey: 0,
            }
        },
        watch: {
            profit_data(newVal) {
                this.series[1].data = newVal; // Update the series for profit
            },
            revenue_data(newVal) {
                this.series[0].data = newVal; // Update the series for revenue
            },
            categories(newVal) {
                console.log("UPDATED")
                this.chartOptions.xaxis.categories = newVal; // Update the categories in the options
                this.options.xaxis.categories = newVal; // Update the categories in the options
                this.refreshChart();
            },
            options: {
                deep: true,
                handler() {
                    this.refreshChart(); // Force re-render on options change
                },
            },
        },
        methods: {
            refreshChart() {
                this.chartKey += 1;
            }
        },
        components: {
            apexchart: VueApexCharts
        },
        
}
    
</script>

<style>
    .child-component {
        width: 100%; /* Matches the parent's width */
        height: 10%;
    }
</style>